@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;400&;700&family=Square+Peg&family=Pathway+Gothic+One&family=Bayon&display=swap');

body {
  width: 100vw;
  /* height: 100vh; */
  margin: 0;
  font-family: 'Catamaran', sans-serif;
  font-weight: 400;
}

:root{
  font-size: 16px;
}

.login-page{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6b705c;
}


.login-page img{
  height:8rem;
  margin: 3.5rem;
}

.auth{
  width: 500px;
  max-width: 90vw;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.auth-options{
  width: 100%;
  text-align: center;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.auth-options button{
  appearance: none;
  background-color: #b7b7a4;
  border: none;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  color: #6b705c;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

button.login-select, .auth-options button:hover {
  color: #fff;
  background-color: #fb5607;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.auth-details{
  padding: 30px 20px;
  justify-self: stretch;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.auth-details div{
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}

.auth-details input{
  width: 50%;
  border: none;
  outline: none;
  border-bottom: 2px solid #6c757d;
  margin: 5px;
  color: #218380;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Catamaran', sans-serif;
}

.auth-details input::placeholder{
  color: #ddbea9;
}

.auth-p{
  margin: 0 25% 10px 0;
  font-size: .8rem;
  align-self: flex-end;
  color: cadetblue;
  cursor: pointer;
}

a{
  text-decoration:underline;
  font-size: .8rem;
  color: cadetblue;
  cursor: pointer;
}

.login-submit-btn{
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  margin-top: 10px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  z-index: 0;
  color: black;
}

.login-submit-btn:after {
  content: "";
  background-color: #ffe44c88;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.login-submit-btn:hover:after {
  top: 0px;
  left: 0px;
}

.login-error{
  font-size: .8rem;
  /* font-family: 'Catamaran', sans-serif; */
  color: #9e2a2b;
  text-align: center;
}

.user-view, .admin-view{
  /* min-height: 100vh; */
  background-color: #f0efeb;

  display: grid;
  grid-template-rows: auto 1fr;
}

.admin-view{
  background-color: #283618;
}

.header{
  /* background-color: #c44536; */
  padding-top: 10px;
  user-select: none;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
}

.header img{
  height: 90px;
  cursor: pointer;
}

.admin-view .header{
  grid-template-columns: 1fr;
}

.fa-bars{
  margin-left: 30px;
  font-size: 2rem;
}

.fa-bars.show{
  color: #bb3e03;
}

.fa-bars:hover{
  color: #bb3e03;
}

.main{
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.admin-btn{
  margin: 20px 30px 10px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: 'Pathway Gothic One', sans-serif;
  font-size: 1.4rem;
  color: #283618;
}

.admin-view .admin-btn{
  color: #f0efeb;
}

.dash{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  user-select: none;
}

.dash-option{
  margin: 0 10px;
  height: 300px;
  /* border: 1px solid black; */
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    color: #f4f1de;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-label{
  text-align: center;
  font-family: 'Square Peg', cursive;
  font-size: 3.2rem;
  margin: 10px;
  /* color: #f4f1de; */
}

.dash-option svg{
  /* color: #f4f1de; */
  font-size: 1.6rem;
}

.dash-option:hover{
  transform: scale(1.01);
}

.map-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.map-search-div{
  display: grid;
  grid-template-rows: auto 1fr;
}

.search{
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.search input, .popup input{
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid #480ca8;
}

.loc-btn{
 margin-right: 10px;
  height: 1.7rem;
  width: 1.7rem;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-location-arrow{
  color: #480ca8;
  font-size: 1rem;
}

#map{
  margin: 10px 10px 0 0;
}

#list, .fav-container, .log-container{
  overflow-y: auto;
  padding: 10px;

  display: grid;
  gap: 10px;
}

#list{
  padding: 0;
  grid-template-rows: auto 1fr;
}

.list-body{
  display: grid;
  gap: 10px;
}

.selected{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.list-item, .fav-item, .log-item{
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.list-name,.list-address, .gas-price-div, svg{
  cursor: pointer;
}

.price-table{
  margin-top: 10px;
  padding: 0 15px 0 0;
  display: grid;
  grid-template-columns: auto repeat(4, 1fr);
  grid-template-rows: auto repeat(2, 1fr);
  gap: 5px;
}

.gas-price-div{
  border: 1px solid #8d99ae;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.gas-price-div p{
  margin: 0;
  font-weight: 100;
  font-size: .6rem;
  line-height: 90%;
  text-align: center;
}

.gas-price{
  margin: 0 5px;
  font-size: 1.2rem;
  font-family: 'Bayon', sans-serif;
}

.gas-method{
  align-self: center;
  transform: rotate(-90deg);
}

.gas-type{
  justify-self: center;
}

.gas-method, .gas-type{
  font-weight: 700;
  font-size: .7rem;
  color: #283618;
}

.star-div{
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.star-div svg{
  font-size: 1.1rem;
}


.menu-container{
  background-color: #b7b7a4;
  width: 200px;
  min-height: 300px;
  border-radius: 5px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-container.hide{
  transform: translate3d(-250px, 80px, 0);
}

.menu-container.show{
  transform: translate3d(70px, 80px, 0);
  overflow: hidden;
}

.menu-container p{
  cursor: pointer;
  margin: 0;
}

#menu-username{
  color: #6d6875;
  font-weight: 100;
  text-transform: uppercase;
  font-size: .8rem;
}

.menu-options{
  font-family: 'Square Peg', cursive;
  font-size: 1.6rem;
}

.menu-options p{
  margin: 5px 0;

  display: flex;
  gap: 15px;
  align-items: center;
}

.menu-options p:hover{
  transform: scale(1.05);
  color: #bb3e03;
}

.menu-options svg{
  font-size: 1rem;
}


.account-options{
  font-weight: 700;
}

.popup-container{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.406);
  z-index: 1;
}

.popup{
  min-width: 200px;
  max-width: 90vw;
  min-height: 200px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: 10px;
  padding: 15px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.confirm-popup{
  width: 400px;
  height: max-content;
  text-align: center;
}

.confirm-buttons{
  display: flex;
  gap: 10px;
}

.update-popup p{
  margin: 0;
}

.update-popup button{
  border: none;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  color: black;
}

.update-popup button:hover{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.price-input{
  font-size: 2rem;
  border-bottom: 2px solid #bb3e03;

  display: flex;
  gap: 2px;
}

.price-input.input-error{
  animation: shake .3s;
}

.number-input{
  width: 35px;
  font-size: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.num-keypad{
  position: absolute;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;
  background-color: #ced4da;
  padding: 10px 40px;

  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 10px;
}

.num-keys{
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 1.4rem;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
}


.main select, .admin-list-top-right>select{
background-color: transparent;
border: none;
outline: none;
font-family: 'Catamaran', sans-serif;
font-weight: 700;
border-bottom: 2px solid #bb3e03;
color: #264653;
cursor: pointer;
justify-self: end;
}

.fav-item{
  padding: 15px;
}

.fav-item-title{
  padding: 0 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  
}

.fav-name, .list-name{
  font-weight: 700;
  margin: 0;
  font-size: 1.1rem;
  color: #3d405b;
}

.fav-address, .list-address, .list-distance{
  font-weight: 100;
  margin: 0;
  font-size: .9rem;
  color: #8d99ae;
}

.log-container select{
  margin-left: 10px;
}

.log-item{
  padding: 20px;

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  align-items: center;
}

.log-label{
  font-size: .8rem;
  font-weight: 100;
  color: #b7b7a4;
}

.log-value button{
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0 10px;
  outline: none;
  padding: 5px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.log-value button:hover{
  background-color: #b3d3ea;
  color: #2c5777;
}

.page-div{
color: #6c757d;
font-weight: 100;
font-size: .8rem;

 display: flex;
 gap: 15px;
 align-items: center;
}

.page-num{
  cursor: pointer;
  font-size: 1.2rem;
}

.page-num.current{
  font-weight: 700;
  color: #bb3e03;
}

.loading-container {
  position: fixed;
  z-index: 1000;
  background-color: #f0efebd7;

  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
}

.loading-container img{
  height: 5rem;
}

.empty{
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #6b705c;
}

.admin-main{
  margin: 10px 10px 40px;
  background-color: #ced4da;
  border: none;
  border-radius: 5px;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.sidebar{
  padding: 20px;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar p{
  font-weight: 700;
  font-family: 'Square Peg', cursive;
  font-size: 2rem;
  color: #480ca8;
}

.admin-list{
  padding: 10px;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.admin-list p{
  margin: 0;
  text-align: center;
}

.admin-list-top{
  margin-bottom: 5px;

  display: flex;
  justify-content: space-between;
}

.admin-list-top svg{
  font-size: 1.4rem;
  color: #2a9d8f;
}

.admin-list-top svg:hover{
  transform: scale(1.05);
}

.admin-userlist-item, .admin-userlist-label{
  margin: 5px 0;
  font-size: .9rem;

  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 1fr;
}

.admin-userlist-label{
  color: #6d6875;
  font-size: .8rem;
}

.admin-userlist-item p, .admin-userlist-label p{
  word-break: break-all;
}

.admin-actions{
  justify-self: center;

  display: flex;
  gap: 10px;
}

.admin-buttons, #forget-submit, .confirm-buttons button{
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #e5e5e5;
  padding: 10px 20px;
  color: black;
}

.edit-user-popup p{
  color: #6c757d;
  font-size: .8rem;
}

.sidebar .admin-selected{
  font-weight: 700;
}

.admin-list-top-right{
  display: flex;
  gap: 10px;
}

.admin-search{
  border: 1px solid #6c757d;
  border-radius: 3px;
  background-color: white;

  display: flex;
  align-items: center;
}

.admin-search select{
  border: none;
  outline: none;
  color: #bb3e03;
  font-weight: 700;
}

.admin-search input{
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 5px;
}

.admin-search svg{
  color: #6c757d;
  font-size: .8rem;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .login-submit-btn {
    padding: 13px 50px 13px;
  }
}

@media (max-width: 768px) {
  .dash{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
  }
  .header img{
    justify-self: flex-end;
    margin-right: 25px;
  }
  .map-container{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
  .admin-main{
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  .sidebar{
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    padding: 10px;
  }
  .sidebar p{
    margin: 0;
  }
  .menu-container.show{
    transform: translate3d(20px, 80px, 0);
  }
  .update-popup{
    transform: translate(-50%, -115%);
  }
  .admin-list-top-right{
    flex-direction: column;
    align-items: flex-end
  }
}

@keyframes shake {
  0% {transform: translate(0, 0);}
  20% {transform: translate(-4px, 0);}
  40% {transform: translate(4px, 0);}
  60% {transform: translate(-4px, 0);}
  80% {transform: translate(4px, 0);}
  100% {transform: translate(0, 0);}
}